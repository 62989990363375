import store from './store';

export default function authMiddleware(to, from, next) {
  const isAuthenticated = store.state.session.user !== null;

  if (isAuthenticated) {
    next();
  } else {
    next('/');
  }
}
