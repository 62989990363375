<template>
  <v-alert
      :value="true"
      :color="color"
      dark
      dismissible
      border="top"
      transition="scale-transition"
  >
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true
    },
    color:{
      type: String,
      required: true
    }
  }
};
</script>